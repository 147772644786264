<template>
    <div id="wrapper">
        <router-view class="view"></router-view>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import log2front from 'oldCommon/utils/log2front';
import ifFromApp from 'oldCommon/mixins/ifFromApp';
import { parseQueryString } from 'oldCommon/utils/common/functions';
const RULE = /^((openanjuke)|(wbmain)|(call-app-method))/;
export default {
    name: 'OldApp',
    mixins: [ifFromApp],
    data() {
        return {
            driver: null
        };
    },

    computed: {
        ...mapGetters({
            request: 'getRequest'
        })
    },

    beforeMount() {
        this.driver = this.$driver;
        let driver = this.driver;
        let clickCB = this.clickCB;
        window.locations = {};
        Object.defineProperty(locations, 'href', {
            set(val) {
                clickCB(val, driver);
            }
        });
    },
    mounted() {
        let driver = this.driver;
        let clickCB = this.clickCB;
        $('body').on('click', 'a', e => {
            e.preventDefault();
            e.stopPropagation();
            let target = e.currentTarget || event.currentTarget;
            // if (target.getAttribute("data-from")) {
            this.bindfrom(e, driver);
            return;
            // }
            // if (target.href) {
            //   clickCB(target.href, driver)
            // }
        });
    },
    methods: {
        /**
         * 拦截APP协议发送click码
         * @param url
         */
        interceptAppMethodToSendSoj(url) {
            RULE.lastIndex = 0;
            if (RULE.test(url)) {
                this.$soj.trackEvent('intercept-app-method', { method: url });
            }
        },

        bindfrom(e, driver) {
            let target = e.currentTarget || event.currentTarget;
            let val = target.getAttribute('data-from') || target.getAttribute('data-soj');
            let newView = target.getAttribute('data-new');
            let isFinish = target.getAttribute('data-finish');
            var href = target.getAttribute('href');

            if (!href) {
                // 此处链接不做合法性检查
                return;
            }
            if (/javascript|tel|history.back/i.test(href)) {
                location.href = href;
                return;
            }
            let currentPath = (this.$route && this.$route.path) || '';
            // 跳原生小程序
            if (currentPath.indexOf('xiaohuanxi') > -1) {
                this.$soj.trackEvent('celllist_click', {
                    desc: '点击房源',
                    pageName: 'xiaohuanxi_home'
                });
            }

            if (this.request.ifFromMiniProgram && href.indexOf('anjuke') === -1) {
                wx && wx.miniProgram.navigateTo({ url: href });
                return;
            }
            if (!val) {
                // 如果无soj,直接跳转
                this.clickCB(href, driver, isFinish, newView);
                return;
            }
            //拦截发码
            this.interceptAppMethodToSendSoj(href);
            RULE.lastIndex = 0;
            if (RULE.test(href) && this.request.isFromApp) {
                driver.jump(href);
            } else {
                // const isFinish = href.indexOf('isFinish=true') > -1
                let _href = href + `${href.indexOf('?') !== -1 ? '&' : '?'}from=${val}`;
                this.urlHandleInApp({
                    url: _href,
                    isFinish,
                    newView
                });
            }
        },
        clickCB(url, driver, isFinish, newView = false) {
            this.interceptAppMethodToSendSoj(url);
            RULE.lastIndex = 0;
            if (RULE.test(url) && this.request.isFromApp) {
                driver.jump(url);
            } else {
                if (!url) {
                    // 此处链接不做合法性检查
                    return;
                }
                if (/javascript|tel|history.back/i.test(url)) {
                    location.href = url;
                    return;
                }
                this.urlHandleInApp({
                    url,
                    isFinish,
                    newView
                });
                // location.href = url
            }
            return false;
        },
        urlHandleInApp({ url, isFinish = false, newView = false } = {}) {
            let _isFinish = isFinish || parseQueryString(url)['isFinish'] || false;
            let _Finish = _isFinish && _isFinish !== 'false';
            url = this.addSourceFlag(url);
            if (this.request.isFromApp && !this.isAjlzApp) {
                //安居乐装app当h5跳转
                if (_Finish) {
                    // this.driver.goWebview(url, true) //页面切换的效果与UED想法有出入,采用replace在当前webview切换
                    location.replace(url);
                } else if (newView) {
                    this.driver.goWebview({ url });
                } else {
                    location.href = url;
                }
            } else {
                if (_Finish) {
                    location.replace(url);
                } else {
                    location.href = url;
                }
            }
        },
        addSourceFlag(url) {
            const sourceFlag = ['source', 'pi', 'clickid'];
            let hasFlag = false;
            _.forEach(sourceFlag, value => {
                if (this.$route.query.hasOwnProperty(value)) {
                    hasFlag = true;
                    return false;
                }
            });
            // if (hasFlag&&_.isString(url) && url && /^https?:\/\//ig.test(url)) {
            if (hasFlag && _.isString(url) && url) {
                let params = parseQueryString(url);
                console.log(parseQueryString, url, parseQueryString(url));
                _.forEach(sourceFlag, value => {
                    if (this.$route.query[value] && !params[value]) {
                        url += `${url.indexOf('?') > -1 ? '&' : '?'}${value}=${this.$route.query[value]}`;
                    }
                });
            }
            return url;
        }
    }
};
</script>
<style lang="sass">
@import './common/scss/index'
</style>
